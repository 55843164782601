import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src281035820/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { FormProps } from '../../components/FormProps';
export const name = 'AttributeDefinitionForm';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  name,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{name}</h2>
    <FormProps fields={[{
      name: 'name',
      type: 'text'
    }, {
      name: 'description',
      type: 'text'
    }, {
      name: 'allowsMultiple',
      type: 'checkbox'
    }]} formOptions={[{
      name: 'kappSlug',
      type: 'string',
      description: 'When provided the form will be populate the kapp. Required if the attribute definition is for a Kapp. (Kapp Attributes, Form Attributes, Category Attributes, ...etc)'
    }, {
      name: 'attributeName',
      type: 'string',
      description: 'The name of the attribute (assumes new if nothing is provided).'
    }, {
      name: 'attributeType',
      required: true,
      type: 'string',
      description: 'Type of attribute. One of: spaceAttributeDefinitions, teamAttributeDefinitions, userAttributeDefinitions, userProfileAttributeDefinitions, categoryAttributeDefinitions, kappAttributeDefinitions, formAttributeDefinitions, datastoreFormAttributeDefinitions'
    }]} dataSources={[{
      name: 'attributeDefinition',
      type: 'AttributeDefinition'
    }]} mdxType="FormProps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      